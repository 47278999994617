import {SystemConfig} from './consts';
const dictionary={
    "EN": {
            "language_EN":"English",    
            "language_ES": "Spanish",
            "contact_us": "Contact Us",
            "any_questions_please_contact_us":"Any questions? Please contact us.",
            "submit": "Submit",
            "name": "Name",
            "email":"Email",
            "suject":"Subject",
            "message": "Message",
            "send": "Send",
            "we_never_share_your_email":"We'll never share your email with anyone else.",
            "view_more":"view more"
      },
    "ES":{
        "language_EN":"Ingles",    
        "language_ES": "Español",
        "contact_us": "Contactanos",
        "any_questions_please_contact_us": "¿Tienes preguntas?, Por favor contacta con nosotros",
        "submit":"Enviar",
        "name": "Nombre",
        "email":"Email",
        "suject":"Titulo",
        "message": "Mensaje",
        "send": "Enviar",
        "we_never_share_your_email":"Para su seguridad, su información no se compartirá con terceras personas",
        "view_more":"Ver más"
        
    }
  };
export function getLabel( key){
    return dictionary[SystemConfig.getLanguage()][key];
}