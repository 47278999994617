import React, {Component} from 'react';
import posed from 'react-pose';
import SplitText from 'react-pose-text';
class PostListItem extends Component{
    constructor(){
        super();
        this.state={
            initialPose:'closed'

        }
    }
    windowHeight=0
    componentDidMount() {
    
    }
    beginAnimation(){
        
    }
    render(){
        let item = this.props.item;
        let isVisible= item.isVisible  || false;;
        let {initialPose} = this.state;
        if(isVisible && initialPose=='closed'){
            setTimeout(() => {
                this.setState({
                        initialPose:'open'
                    });
            }, 200);
        }
        let media = this.props.media;
        const extraClassName = (this.props.index % 2 ===0 )? 'post-item-1-second' : 'post-item-1-primary';
                
        let clssName = "col-sm col-md-6 " + extraClassName;
        let imgs =null;
        if(media){
            imgs = media.data.map((s)=>{
                return  <img className="img-fluid" key={s.id} src={s.links.full} alt = "" />;
            });
        }
        const Txtbox = posed.div({
            closed: {
                x: '-100%',
                opacity:0
                
              },
            open: {
                x: '0%',
                opacity:1,
                beforeChildren: true,
                staggerChildren: 50,
                transition: { duration: 400 }
            }
            
        });
        const charPoses = {
            closed: { opacity: 0 },
            open: { opacity: 1 }
            
        };
        const txt = <Txtbox className ={`wrapper poseobj-${item.id}`} initialPose={initialPose} pose={isVisible ? 'open' : 'closed'}>
                        <h1>
                            {item.title}
                        </h1>
                            <div  dangerouslySetInnerHTML={{__html: item.content}}>
                        </div>
                    </Txtbox>;
        
        
            return <div className={`row txt-box no-gutters flex-sm-row ${(this.props.index % 2 === 0) ? 'flex-row': 'flex-md-row-reverse'} `}>
                        <div className={clssName}>
                            {txt}
                        </div>
                        <div className="col-sm col-md-6 post-item-1-gallery">
                            {imgs}
                        </div>
                        
                    </div>
        
    }
}

export default PostListItem;                  