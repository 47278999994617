import React, { Component } from 'react';


class ContactMap extends Component {
  repos = []; 
  constructor(){
    super();
  }
  
  render() {

    return (
            
            <section>
                <div className="wrapper-primary">
                    <p className="address">Meridiano 1699 Col. Industrial Cosmopolis </p>
                    <p style={{"margin-left":"40px"}}> Apodaca N.L. C.P. 66612</p>
                    <p className="phone"> 
                        <a href="tel:8113420133">81 1342 0133</a>
                    </p>
                    <p className="email"><a href="mailto:info@msidur.mx">info@msidur.mx</a></p>
                </div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3591.6456774186818!2d-100.25013998454325!3d25.815261012681074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662ecd9ee4eb747%3A0xb4c13a930347ad8a!2sMeridiano%201699%2C%20Paseos%20de%20Apodaca%2C%20Cd%20Apodaca%2C%20N.L.!5e0!3m2!1sen!2smx!4v1617838030955!5m2!1sen!2smx" width="100%" height="450" frameborder="0"  allowfullscreen></iframe>
            </section>
    );
  }
}


export default ContactMap;
