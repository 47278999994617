
import buildUrl from '../../node_modules/build-url/dist/build-url';
//api services
//dev

//const URI= 'http://localhost:8888/sidur/api/crm/public/index.php/';
//export const BASE_URI ='/';

//prod
const URI= 'https://msidur.mx/api/api/';
export const BASE_URI ='/';


// implementation Types
const MOCK ="Mock";
const DB ="DB";

//category keys
const Main_Page_Category = 'pagina-principal';
const Carrousell_Main_Page= 'carrousel-pagina-principal';
const Carrousell_About_Us= 'carrousel-nosotros';
const Carrousell_Equipment="carrousel-equipos";
const Out_Clients = 'nuestros-clientes';
const About_Us = 'nosotros';
const Service_Cat = 'servicios';
const Equipment_Cat = 'equipos';

//section keys
const AboutUs= 'nosotros';
const Services= 'servicios';
const Equipments= 'equipos';
const Contact= 'contacto';

export class TypeImplementation {
    
    static get Mock (){ return MOCK;}
    static get DB() {return DB;}
    
}

export class EndPoints {
    static get Pages() { return URI + "pc/v1/pages"}
    static get Categories() {return URI + "pc/v1/posts/categories"}
    static get PostsCategory () {return URI + "pc/v1/posts"}
    static get NotificationMail () {return URI + "nm/v1/mail"}
    static MediaPost(postId) {
        let uri= buildUrl(URI, {
            path: 'pc/v1/posts/' + postId + '/media'
            
        });
        return uri;
    }
}
export class CategoryNames{
    
    static get Main_Page() {return Main_Page_Category};
    static get Carrousell_Main_Page(){ return Carrousell_Main_Page; }
    static get Carrousell_Equipment(){ return Carrousell_Equipment; }
    static get Carrousell_About_Us(){ return Carrousell_About_Us;}
    static get Our_Clients() {return Out_Clients; }
    static get About_Us() {return About_Us;}
    static get Services() {return Service_Cat; }
    static get Equipments() {return Equipment_Cat; }
}

export class SectionNames{
    static get AboutUs() {return AboutUs};
    static get Services() {return Services};
    static get Equipments() {return Equipments};
    static get Contact() {return Contact;}
}

export class SystemConfig{
    static currentLanguageCode='ES';
    static countryCode= 'MX';
    static Sections={
        servicios:{
            showChildren : false
        }
        
    }
    static getLanguage(){
        return this.currentLanguageCode;
    }
    static getAcceptLanguage(){
        return this.currentLanguageCode + "-" + this.countryCode;
    }
    static setLanguage(languageCode){
        if(languageCode)
            this.currentLanguageCode= languageCode;
    }
}

export class UriUtils{
    static getRouteParams(routeInfo, paramName){
        return (routeInfo && routeInfo.match) ? routeInfo.match.params[paramName] : null;
    }
    static ReplaceCurrentLinkParam(routeInfo, paramName, paramValue){
        let  key= ':' + paramName;
        return (routeInfo && routeInfo.match) ? routeInfo.match.path.replace(key, paramValue) : '';
    }
    
}

String.prototype.capitalize = function(lower= true) {

    return (lower ? this.toLowerCase() : this).replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
};