import {TypeImplementation} from "../../models/consts";
import MockMediaRepository from '../repositories/MockMediaRepository';
import MediaRepository from '../repositories/MediaRepository';

//import _ from 'lodash';

class MediaService {
    
    
    constructor(typeImpl){
        this.repo = TypeImplementation.DB;
        if(typeImpl=== TypeImplementation.Mock){
            this.repo = new MockMediaRepository();
        }
        else if(typeImpl === TypeImplementation.DB){
            this.repo= new MediaRepository();
        }
    }

    getMediaPost(postId){
        return  this.repo.getMediaPost(postId);
    }

}

export default MediaService;