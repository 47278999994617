import React, { Component } from 'react';


import {TypeImplementation, CategoryNames, UriUtils, SystemConfig} from '../../models/consts';
import PostList from '../PostList';
import PostListItem from '../PostListItem';
import Footer from '../Footer';
import OurClients from '../OurClients'
import Header from '../Header'
import HeaderHome from '../HeaderHome';
import ContactFooter from '../ContactFooter'
class Home extends Component {
  
  constructor(){
    super();
  }
  
  componentDidMount() {
    
    
    ;
  }

  render() {
    document.documentElement.scrollTop=0;
    const headerContent = <HeaderHome repos={this.props.repos} category={CategoryNames.Carrousell_Main_Page}/>;
    return (
            
            <section>
                <Header repos={this.props.repos} headerClassName="home-header" sections={this.props.sections} routeInfo={this.props.routeInfo} content ={headerContent}/>
                <div className="position-relative">
                      <PostList repos={this.props.repos} category={CategoryNames.Main_Page} renderItem={(idx, id, item, media)=>{return <PostListItem index={idx} key={id} item={item} media={media} />}} />
                      {/*<OurClients repos={this.props.repos} category={CategoryNames.Our_Clients}/>*/}
                      <ContactFooter />
                      <Footer repos={this.props.repos}/>
                      
                </div>
            </section>
            
    );
  }
}


export default Home;
