import React, {Component} from 'react';
import { Link } from "react-router-dom";
import {SystemConfig, BASE_URI} from "../models/consts";

class Breadcrumb extends Component {
    
    constructor(){
        super();
        this.state={
            sections:{data:[]}
        };
    }

    componentDidMount() {
        
        this.props.repos.sectionService.getSections()
            .then(data => this.setState({ sections: data }));
    }

    render(){
        const languageCode = SystemConfig.getLanguage();
        const paths =(this.props.section ) ? this.props.section.path.split("/"): [] 
        const parent = (this.state.sections && this.state.sections.data && this.state.sections.data.length>0) ? this.state.sections.data[0]: null;
        let items=[];
        let path = BASE_URI;
        for(let i=0, j=paths.length; i<j; i++){
            const p = path + paths[i] + "/" + languageCode;
            items.push(<li key={i} className={`breadcrumb-item ${i==(j-1)? "active": ""}`}>
                            <Link to={p}>{paths[i].capitalize().replace(/-/g, " ")}</Link>
                        </li>)
            path += paths[i] + "/" + languageCode;
        }
        
        return <nav className="breadcrumbWrapper" aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to={`/${(parent) ?  parent.path + "/" + languageCode : ""}`}>{(parent) ? parent.title.capitalize().replace(/-/g, " ") : null}</Link>
                            </li>
                            {items}
                        </ol>
                </nav>
        
    }
}

export default Breadcrumb;