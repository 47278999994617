import React, { Component } from 'react';

import {TypeImplementation, CategoryNames} from '../../models/consts';
import PostList from '../PostList';
import Header from '../Header'
import HeaderSection from '../HeaderSection';
import ContactMap from '../ContactMap';
import ContactForm from '../ContactForm';
import Footer from '../Footer';
import { getLabel } from '../../models/labels';

class Contact extends Component {
  
  constructor(){
    
    super();
    this.repos = []; 
  }
  
  
  render() {
    document.documentElement.scrollTop=0;
    const headerContent = <HeaderSection repos={this.props.repos} category={CategoryNames.Carrousell_Main_Page} sectionName={this.props.sectionName} />;

    return (
            <section>
              <Header repos={this.props.repos} headerClassName="section-header" sections={this.props.sections} routeInfo={this.props.routeInfo} content ={headerContent} />
              <div className="position-relative wrapper-white">
                <div className="container">
                  <div className="row">
                    <div className="col  col-12 col-md-6">
                        <h4>{getLabel('any_questions_please_contact_us')}</h4>
                        <ContactForm repos={this.props.repos}/>
                    </div>
                    <div className="col col-12 col-md-6 " >
                        <ContactMap />
                    </div>
                  </div>
                </div>
                <Footer repos={this.props.repos}/>
            </div>
            </section>
    );
  }
}


export default Contact;
