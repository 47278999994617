import React, {Component} from 'react';
import { Link } from "react-router-dom";
import {SystemConfig, BASE_URI} from "../models/consts";
class Footer extends Component{
    
    render(){
        const year = (new Date()).getFullYear();
        return <div className="position-relative">
                    <footer>
                    <div className="col-sm">
                        <Link to={BASE_URI} className="navbar-brand">
                            <img src={`${BASE_URI}assets/sidur-logo.png`} alt="sidur logo"/>
                        </Link>
                    </div>
                    <div className="row pt-4">
                        <div className="col-sm col-md-9">
                            <label className="address pr-4 col-sm-12 col-lg-6">
                                <Link  to={`${BASE_URI}contacto/${SystemConfig.getLanguage()}`}>Meridiano 1699 Col. Industrial Cosmopolis Apodaca N.L. C.P. 66612</Link>
                            </label>
                            <label className="phone pr-4 col-sm-12 col-lg-3">
                                <a href="tel:8113420133">81 1342 0133</a>
                                
                            </label>
                            <label className="email pr-4 col-sm-12 col-lg-3"><a href="mailto:info@msidur.mx">info@msidur.mx</a></label>
                        </div>
                        <div className="col-sm col-md-3">
                                <p>© {year}, SIDUR SA de CV </p>
                        </div>
                    </div>
                    
                            
                    </footer>
                </div>
    }
}

export default Footer;