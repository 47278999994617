import {TypeImplementation} from "../../models/consts";
import MockNotificationRepository from '../repositories/MockNotificationRepository';
import NotificationRepository from '../repositories/NotificationRepository';

class NotificationService {
    
    
    constructor(typeImpl){
        this.repo = TypeImplementation.DB;
        if(typeImpl=== TypeImplementation.Mock){
            this.repo = new MockNotificationRepository();
        }
        else if(typeImpl === TypeImplementation.DB){
            this.repo= new NotificationRepository();
        }
    }

    sendEmail(mailInfo){
        return  this.repo.sendEmail(mailInfo);
    }

}

export default NotificationService;