import * as mocks from '../../mocks/categories';


class MockCategoriesRepository {
    getCategories(){
        return Promise.resolve(mocks.getCategories());
    }
    
    getCategoryPosts(categoryId, extraParams){
        return Promise.resolve(mocks.getCategoryPosts(categoryId));
    }
}
export default MockCategoriesRepository;