import React, {Component} from 'react';
import NavigateBar from './NavigateBar';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Breadcrumb from './Breadcrumb';

class HeaderHome extends Component{
    
    constructor(){
        super();
        this.state={
            section:null,
            carrousel:{data:[]}

        };
    }

    componentDidMount() {
        const categoryName = this.props.category;
        const sectionName = this.props.sectionName;
        this.props.repos.categoryService.getCategoryPostsMedia(categoryName)
            .then(data => this.setState({ carrousel: data }));
        this.props.repos.sectionService.getSectionByName(sectionName)
            .then(data => this.setState({ section: data }));
    }
    
    render(){
        
        const { carrousel, section } = this.state;

        let carrsouselData=null;
        let imgs= null;
        if(carrousel){
            carrsouselData = carrousel.data ? carrousel.data :[];
            if(carrsouselData.length>0){
                let item = carrsouselData[0];
                if(item.media && item.media.length>0){
                    imgs= <img src={item.media[0].links.full} alt="carrousel"/>
                }
            }         
        }
        
        return <div className="top-carousel">
                            {imgs}
                            <h1 className={`legend ${(section && section.title.length>15)? 'd-none d-sm-block':''}`}>{(section)? section.title:null}</h1>
                            <Breadcrumb repos= {this.props.repos} section={section}/>
                            
                </div>
    }
}

export default HeaderHome;