import React, {Component} from 'react';
import {BASE_URI,  SystemConfig} from '../models/consts';
import {getLabel} from '../models/labels';
import posed from 'react-pose';
import { Link } from "react-router-dom";
class ContactFooter extends Component{
    
    constructor(){
        super();
        this.state={
            isVisible:false,
            initialPose:'closed'
        };
        this.checkPosition = this.checkPosition.bind(this);
    }
    listitems=null
    
    componentDidMount() {
        window.addEventListener('scroll', this.checkPosition);
    }

    checkPosition(event){        
        const windowHeight = window.innerHeight;
        const el = document.querySelectorAll('.posefooter');
        if(el!=null && el.length>0){
            
            var positionFromTop = el[0].getBoundingClientRect().top;
            var porcentage= ((positionFromTop*100) / windowHeight).toFixed(0);
            //console.log('windows:' + windowHeight + '| positionFromTop:' + positionFromTop + ' %' +porcentage);
            if(porcentage<=80){
                
                this.setState({
                    isVisible:true
                });
                setTimeout(() => {
                    this.setState({
                            initialPose:'open'
                        });
                }, 200);
                
            }
        } 
    }

    render(){
        const year = (new Date()).getFullYear();
        let {initialPose, isVisible} = this.state;
        const Posebox = posed.div({
            closed: {
                y: 30,
                opacity:0
                
              },
            open: {
                y:0,
                opacity:1,
                beforeChildren: true,
                staggerChildren: 50,
                transition: { duration: 400 }
            }
            
        });

        return <div className="position-relative wrapper-primary padding-x5 posefooter" >
                    <div className= "row justify-content-md-center">
                        <Posebox className="col-md-12 posefooter"  initialPose={initialPose} pose={isVisible ? 'open' : 'closed'}>
                            <h2 className="text-center">{getLabel('contact_us')}</h2>
                            <p className="text-center">{getLabel('any_questions_please_contact_us')}</p>
                        </Posebox>
                    </div>
                    <Posebox className= "d-flex justify-content-center posefooter"  initialPose={initialPose} pose={isVisible ? 'open' : 'closed'}>
                            
                                <Link to={`${BASE_URI}contacto/${SystemConfig.getLanguage()}`} className="btn btn-light text-center font-2 pl-4 pr-4">{getLabel("submit")}</Link>
                            
                    </Posebox>
                </div>
    }
}

export default ContactFooter;