import React, { Component } from 'react';
import {getLabel} from '../models/labels';
class ContactForm extends Component {
  repos = []; 
  constructor(){
    super();
    this.state={
        name:null,
        email:null,
        message:null,
        response:null
    };
    this.handleNameChange= this.handleNameChange.bind(this);
    this.handleEmailChange= this.handleEmailChange.bind(this);
    this.handleMessageChange= this.handleMessageChange.bind(this);
    this.handleSubmit= this.handleSubmit.bind(this);
  }
  
  handleNameChange(event){
      this.setState({name:event.target.value});
  }
  handleEmailChange(event){
        this.setState({email:event.target.value})
  }
  handleMessageChange(event){
      this.setState({message:event.target.value});
  }
  handleSubmit(event){
    event.preventDefault();
    this.props.repos.notificationService.sendEmail(this.state)
        .then(data => this.setState({ response: data }));
    
  }

  componentDidMount() {
    const sectionName = this.props.sectionName;
    
  }
  render() {
    let visibilityState = (this.state.response) ? "visible" : "hidden";
    let responseClassName= (this.state.response && this.state.response.success)? "alert alert-primary" : "alert alert-danger";

    return (
        <form onSubmit={this.handleSubmit}>
            <div className="form-group">
                <label >{getLabel('name')}</label>
                <input type="text"  value={this.state.name} onChange={this.handleNameChange}  className="form-control" id="contactName" />
            </div>
            <div className="form-group">
                <label >{getLabel('email')}</label>
                <input type="email" value={this.state.email} onChange={this.handleEmailChange} className="form-control" id="contactEmail" aria-describedby="emailHelp" />
                <small id="emailHelp" className="form-text text-muted">{getLabel("we_never_share_your_email")}</small>
            </div>
            <div className="form-group">
                <label >{getLabel('message')}</label>
                <textarea value={this.state.message} onChange={this.handleMessageChange}  id="contactMsg" className="form-control" rows="4"></textarea>
            </div>
            <button type="submit" className="btn btn-primary">{getLabel('send')}</button>
            <div className="form-group pt-2">
                <div className={responseClassName} role="alert"  style={{visibility:visibilityState}}>
                    {this.state.response ? this.state.response.message:null}
                </div>    
            </div>
        </form>
    );
  }
}


export default ContactForm;
