import React, {Component} from 'react';
import { Link } from "react-router-dom";
import {SystemConfig, BASE_URI} from "../models/consts";
import NavigateLinkBar from './NavigateLinkBar';

class NavigateBar extends Component {
    
    render(){
        
        return <nav className="navbar navbar-top navbar-expand-lg navbar-dark">
                    
                    <Link to={BASE_URI + "home/" + SystemConfig.getLanguage()} className="navbar-brand">
                        <img src={`${BASE_URI}assets/sidur-logo.png`} alt="sidur logo"/>
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon navbar-toggler-icon-dark"></span>
                    </button>    
                
                    <div className="collapse navbar-collapse " id="navbarTogglerDemo02">
                        <NavigateLinkBar sections={this.props.sections} routeInfo={this.props.routeInfo}/>
                    </div>
                </nav>;
        
    }
}

export default NavigateBar;