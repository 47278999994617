import React, {Component} from 'react';

import PostListItemMedia from './PostListItemMedia';

class OurClients extends Component{
    constructor(){
        super();
        this.state={
            categoryPosts:{
                data:[]
            }
        }
    }
    
    componentDidMount() {
        const categoryName = this.props.category;
        this.props.repos.categoryService.getCategoryPostsMedia(categoryName)
            .then((cat) => this.setState({ categoryPosts: cat }));
    }

    render(){
        let idx = 0;
        let listitems=null;
        if(this.state.categoryPosts){
            listitems = this.state.categoryPosts.data.map((s)=>{
                let media=null;
                if(s.media)
                    media ={data:s.media};
                return  <div className="row no-gutters" key={s.id}>
                            <div className="col col-md-12">
                                <h1>{s.title}</h1>
                                <div className="content" dangerouslySetInnerHTML={{__html: s.content}} />
                                <PostListItemMedia key={s.id} media={media}/>
                            </div>
                            
                        </div>
            });
         
            
        }
        return <div className='wrapper-white position-relative'>
                    {listitems}
                    
                </div>
        
    }
}

export default OurClients;