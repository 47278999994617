import {SystemConfig, TypeImplementation} from "../../models/consts";
import MockCategoriesRepository from '../repositories/MockCategoriesRepository';
import MockMediaRepository from '../repositories/MockMediaRepository';

import CategoriesRepository from '../repositories/CategoriesRepository';
import MediaRepository from '../repositories/MediaRepository';
import _ from 'lodash';

class CategoriesService {
    
    
    constructor(typeImpl){
        this.categories=[];
        if(typeImpl=== TypeImplementation.Mock){
            this.repo = new MockCategoriesRepository();
            this.mediaService = new MockMediaRepository();
        }
        else if(typeImpl === TypeImplementation.DB){
            this.repo= new CategoriesRepository();
            this.mediaService = new MediaRepository();
        }
    }

    getCategories(){
        var languageCode= SystemConfig.getLanguage();
        if(!this.categories[languageCode]){
            this.categories[languageCode] =  this.repo.getCategories()
                .then((categories)=>{return this.generateTreeCategories(categories)});
        }
        return this.categories[languageCode];
        
    }
    
    getCategoryByName(categoryName){
        return this.getCategories()
            .then((categories)=>{
            let data=null;
            if(categoryName){
                data = _.find(categories.data,{'name': categoryName})
                if(data==null){
                    for(let p of _.filter(categories.data, (d)=>{return d.children && d.children.length>0;})){
                        data = _.find(p.children,{'name': categoryName})
                        if(data!=null) break;                       
                    }
                }
            }
            return data;
        });
        
    }

    getCategoryPosts(categoryName, extraParams=null){
        return this.getCategoryByName(categoryName)
            .then((category)=>{
                if(category)
                    return this.repo.getCategoryPosts(category.id, extraParams);
                
            });
    }

    getCategoryPostsMedia(categoryName){
        const extraParams= {'include':'media'};
        return this.getCategoryPosts(categoryName, extraParams);
            
    }

    generateTreeCategories(categories){
        let tree = [];
        const data = categories.data;
        
        for(let s of data){
            if(s.parent!=null){
                const parent = _.find(data,{'id': s.parent})
                if(parent!=null){
                    if(!parent.children) parent.children=[];
                    parent.children.push(s);
                }
            }
        }
        
        tree = _.filter(data, {'parent':null});
        return {data: tree};
    }
    
}

export default CategoriesService;