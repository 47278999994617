import React, { Component } from 'react';
import StackGrid, { transitions } from "react-stack-grid";
import sizeMe from 'react-sizeme'

import {UriUtils, CategoryNames} from '../../models/consts';
import PostList from '../PostList';
import PostCardListItem from '../PostCardListItem'
import Footer from '../Footer';
import Header from '../Header'
import HeaderSection from '../HeaderSection';
import ContactFooter from '../ContactFooter'

class ServiceDetail extends Component {
  
  constructor(){
    
    super();
    this.repos = []; 
    this.state={
        sectionName:null,
        data:{},
        carrousel:{data:[]},
        categoryData:{}
    };
  }
  
  componentDidMount() {
    const sectionName = UriUtils.getRouteParams(this.props.routeInfo, 'sectionId');
    
    this.props.repos.sectionService.getSectionByName(sectionName)
        .then(data => {
          this.setState({ data: data });
          this.props.repos.mediaService.getMediaPost(data.id)
            .then(data => this.setState({ carrousel: data }));
          
      });   
    
    this.setState({sectionName:sectionName});
  }

  render() {
    document.documentElement.scrollTop=0;
    let content= this.state.data  ? this.state.data.content :null;
    const { carrousel } = this.state;
    const { scaleDown } = transitions;
    let carrsouselData=null;
    const { 
      size: { 
        width
      } 
    } = this.props;

    if(carrousel){
        carrsouselData = carrousel.data.map((item, index)=>{
        let imgs= null;
        if(item.links){
            imgs= <img src={item.links.full} className="img-fluid "/>
        }
       
        return <div className="stackgriditem"  key={item.id}>
                        {imgs}
                </div>
               
        
    });    
    }
    
    const  headerContent = (this.state.sectionName ) ? <HeaderSection repos={this.props.repos} category={CategoryNames.Carrousell_Main_Page} sectionName={this.state.sectionName} /> :null;
    return (
      <section>
            <Header repos={this.props.repos} headerClassName="section-header" sections={this.props.sections} routeInfo={this.props.routeInfo} content ={headerContent}/>
            <div className="position-relative wrapper-white">
                <div className="container">
                  <div className="row">
                    <div className="col col-md-12">
                        <section className="padding-md" dangerouslySetInnerHTML={{__html: content}} />
                    </div>
                  </div>
                  <div className="row">
                  <div className="col col-md-12">
                      <StackGrid  duration={2000} gutterWidth={10} 
                        columnWidth={width <= 768 ? '50%' : '30%'}
                        monitorImagesLoaded={true} 
                        appear={scaleDown.appear}
                        appeared={scaleDown.appeared}
                        enter={scaleDown.enter}
                        entered={scaleDown.entered}
                        leaved={scaleDown.leaved}>
                          {carrsouselData}
                      </StackGrid>
                  </div>
                  
                        
                  </div>
                </div>
                <ContactFooter/>
                <Footer repos={this.props.repos}/>
            </div>
      </section>

    );
  }
}


export default sizeMe()(ServiceDetail);