import React, {Component} from 'react';
import { Link } from "react-router-dom";
//import {Link} from '@reach/router';
import {SystemConfig, BASE_URI, UriUtils} from "../models/consts";
import {getLabel} from '../models/labels';
class NavigateLinkBar extends Component {
    
    render(){
        let sections = this.props.sections ? this.props.sections:[];
        let currentLink= UriUtils.getRouteParams(this.props.routeInfo,'current');
        const links = sections.map((s)=>{
            let isAvailableChildren = (SystemConfig.Sections[s.path]) ? SystemConfig.Sections[s.path].showChildren : true;
            let uri= BASE_URI + s.path + "/" + SystemConfig.getLanguage();
            let childrenWrapper = null;
            
            const className = (s.children && isAvailableChildren) ? "nav-item dropdown" : "nav-item";
            const linkClassName = (s.children && isAvailableChildren) ? "nav-link dropdown-toggle": "nav-link";
            let link = <Link id={`navbar${s.id}`} to={uri}  className={linkClassName} >{s.title}</Link>
            if(s.children &&  isAvailableChildren ){
                
                const children = s.children.map((ch)=>{                    
                    return <a className="dropdown-item" href={`/${ch.path}`}>{ch.title}</a>
                });
                childrenWrapper = <div className="dropdown-menu" aria-labelledby={`navbar${s.id}`}>
                                    {children}
                                </div>;
                link= <Link id={`navbar${s.id}`} to={uri}  className={linkClassName} role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{s.title}</Link>;
            }
            return  <li className={className} key={s.id}>                         
                        {link}
                        {childrenWrapper}
                    </li>;
        });
         
        return <ul className="navbar-nav navbar-top navbar-primary align-items-end ml-auto p-2 mt-lg-0 ">
                    {links}
                    <li className="nav-item-divider d-none d-md-block">
                    <span className="nav-link">
                        <span></span>
                    </span>
                    </li>
                    <li className="nav-item dropdown d-none d-md-block pr-4"> 
                        <Link id="navbarLanguage" to="/"  className="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >{getLabel('language_' + SystemConfig.getLanguage())}</Link>
                        <div className="dropdown-menu navbar-language" aria-labelledby="navbarLanguage">
                            <Link className="dropdown-item" onClick={this.forceUpdate} to={UriUtils.ReplaceCurrentLinkParam(this.props.routeInfo,"languageCode","ES")}>{getLabel('language_ES')}</Link>
                            <div className="dropdown-divider"></div>
                            <Link className="dropdown-item" onClick={this.forceUpdate} to={UriUtils.ReplaceCurrentLinkParam(this.props.routeInfo, "languageCode","EN")}>{getLabel('language_EN')}</Link>
                        </div>
                        
                    </li>
                </ul>
            ;
    }
}

export default NavigateLinkBar;