import React, { Component } from 'react';
import {  Route, Switch } from "react-router-dom";

import SectionsService from './business/services/SectionsService';
import CategoriesService from './business/services/CategoriesService';
import MediaService from './business/services/MediaService';
import NotificationService from './business/services/NotificationService';
import {TypeImplementation, BASE_URI, SectionNames} from './models/consts';
import Header from './components/Header';
import Home from './components/Pages/Home'
import ScrollAnimation from './components/ScrollAnimation';
import AboutUs from './components/Pages/AboutUs'
import Services from './components/Pages/Services'
import Contact from './components/Pages/Contact'
import Equipments from './components/Pages/Equipments'
import ServiceDetail from './components/Pages/ServiceDetail';

class App extends Component {
  
  constructor(){
    super();
    this.repos = {
        sectionService : new SectionsService(TypeImplementation.DB),
        categoryService : new CategoriesService(TypeImplementation.DB),
        mediaService : new MediaService(TypeImplementation.DB),
        notificationService : new NotificationService(TypeImplementation.DB)
    };
    this.state={
      sections:{data:[]}
  };
  }
  componentDidMount(){
    this.repos.sectionService.getSections()
            .then(data => this.setState({ sections: data }));
  }
  render() {
    
          
    return (
        <main>
            
            <Switch>
                  <Route  exact path={BASE_URI} render={() => <Home repos={this.repos} sections={this.state.sections}/>}/>
                  <Route  path={`${BASE_URI}home/:languageCode`} render={(routeInfo) => <Home repos={this.repos} sections={this.state.sections} routeInfo={routeInfo} />}/>
                  <Route  path={`${BASE_URI}nosotros/:languageCode`} render={(routeInfo) => <AboutUs repos={this.repos} sections={this.state.sections} sectionName={SectionNames.AboutUs} routeInfo={routeInfo}/>} />
                  <Route  exact path={`${BASE_URI}servicios/:languageCode`} render={(routeInfo) => <Services repos={this.repos} sections={this.state.sections} sectionName={SectionNames.Services} routeInfo={routeInfo} />} />
                  <Route  exact path={`${BASE_URI}equipos/:languageCode`} render={(routeInfo) => <Equipments repos={this.repos} sections={this.state.sections} sectionName={SectionNames.Equipments} routeInfo={routeInfo} />} />
                  <Route  path={`${BASE_URI}servicios/:sectionId/:languageCode`} render={(routeInfo) => <ServiceDetail repos={this.repos} sections={this.state.sections} routeInfo={routeInfo} />} />
                  <Route  path={`${BASE_URI}contacto/:languageCode`} render={(routeInfo) => <Contact repos={this.repos} sections={this.state.sections} sectionName={SectionNames.Contact} routeInfo={routeInfo} />} />
                  <Route  render={() => <Home repos={this.repos} />} />
            </Switch>
                  
                
            
            
        </main>
        
    );
  }
}


export default App; 
