import {SystemConfig,  EndPoints} from "../../models/consts";
class MediaRepository {
    getMediaPost(postId){
        return  fetch(EndPoints.MediaPost(postId), {
            method: 'GET',
            headers: new Headers({
                'Accept-Language':SystemConfig.getAcceptLanguage()
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            responseJson.id = postId;
            return responseJson;
        })
        .catch((error) => {
            console.error(error);
        });
        
    }

}
export default MediaRepository;
