import React, {Component} from 'react';
import NavigateBar from './NavigateBar';
import {UriUtils, SystemConfig} from '../models/consts';

import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class Header extends Component{
    
    constructor(){
        super();
        this.state={
            carrousel:{data:[]}
        };
    }

    
    render(){
        const languageCode = UriUtils.getRouteParams(this.props.routeInfo, 'languageCode');
        SystemConfig.setLanguage(languageCode);
        
        let sectionsData= this.props.sections ? this.props.sections.data: null;        
        return <div className="container-fluid">
                    <div className = {this.props.headerClassName}>
                        <NavigateBar sections={sectionsData} routeInfo={this.props.routeInfo} />
                        
                        {this.props.content}
                    </div>
                </div>
    }
}

export default Header;