import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
//import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

import './styles/sidur.css';
import '../node_modules/jquery/dist/jquery.min.js';
import '../node_modules/popper.js/dist/popper.min.js';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
import { BrowserRouter , Route, Link } from "react-router-dom";

ReactDOM.render(<BrowserRouter>
<App /> 
</BrowserRouter>, document.getElementById('root'));
registerServiceWorker();
