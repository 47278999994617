import {TypeImplementation} from "../../models/consts";
import MockSectionsRepository from '../repositories/MockSectionsRepository';
import SectionsRepository from '../repositories/SectionsRepository';
import _ from 'lodash';
import {SystemConfig} from '../../models/consts';

class SectionsService {
    sections={};
    
    constructor(typeImpl){
        
        if(typeImpl=== TypeImplementation.Mock){
            this.repo = new MockSectionsRepository();
        }
        else if(typeImpl === TypeImplementation.DB){
            this.repo= new SectionsRepository();
        }
    }

    getSections(props){
        var languageCode= SystemConfig.getLanguage();
        if(this.sections[languageCode]==null){
            this.sections[languageCode]= this.repo.getSections(props)
                .then((section)=>{ return this.generateTreeSection(section)});
        }
        return  this.sections[languageCode];

    }

    getSectionByName(sectionName){
        return this.getSections()
        .then((page)=>{
            let data=null;
            if(sectionName){
                data = _.find(page.data,{'path': sectionName})
                if(data==null){
                    for(let p of _.filter(page.data, (d)=>{return d.children && d.children.length>0;})){
                        data = _.find(p.children,{'name': sectionName})
                        if(data!=null) break;                       
                    }
                }
                
            }
            return data;
        });
    }

    generateTreeSection(sections){
        let tree = [];
        const data = sections.data;
        
        for(let s of data){
            if(s.parent!=null){
                const parent = _.find(data,{'id': s.parent})
                if(parent!=null){
                    if(!parent.children) parent.children=[];
                    s.path= parent.path + "/" + s.path;
                    parent.children.push(s);
                }
            }
        }
        
        tree = _.filter(data, {'parent':null});
        return {data: tree};
    }
}

export default SectionsService;