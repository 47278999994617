import React, { Component } from 'react';

import {UriUtils, SystemConfig, CategoryNames} from '../../models/consts';
import PostList from '../PostList';
import PostBarListItem from '../PostBarListItem';
import Footer from '../Footer'
import Header from '../Header'
import HeaderSection from '../HeaderSection';
import ContactFooter from '../ContactFooter'
class AboutUs extends Component {
  
  constructor(){
      super();
      this.state={
        data:{}
    };
  }

  componentDidMount() {
    const sectionName = this.props.sectionName;
    this.props.repos.sectionService.getSectionByName(sectionName)
        .then(data => this.setState({ data: data }));
  }
  
  render() {
    document.documentElement.scrollTop=0;
    let content= this.state.data.content;
    const headerContent = <HeaderSection repos={this.props.repos} category={CategoryNames.Carrousell_About_Us} sectionName={this.props.sectionName} routeInfo={this.props.routeInfo} />;
    return (
            <section>
                <Header repos={this.props.repos} headerClassName="section-header" sections={this.props.sections} content ={headerContent}/>
                <div className="position-relative">
                    <div className="wrapper-white">
                      <div className="row no-gutters">
                        <div className="col col-md-12">
                            <section className="padding-md" dangerouslySetInnerHTML={{__html: content}} />
                        </div>
                      </div>
                    </div>
                    <PostList repos={this.props.repos} category={CategoryNames.About_Us} renderItem={(idx, id, item, media)=>{return <PostBarListItem index={idx} key={id} item={item} media={media}/>}}/>
                    <ContactFooter />
                    <Footer repos={this.props.repos}/>
                </div>
              </section>
    );
  }
}


export default AboutUs;
