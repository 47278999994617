import React, {Component} from 'react';
import _ from 'lodash';
import posed from 'react-pose';

class PostList extends Component{
    
    constructor(){
        super();
        this.state={
            categoryPosts:{
                data:[]
            }
        }
        this.checkPosition = this.checkPosition.bind(this);
    }
    listitems=null
    
    componentDidMount() {
        window.addEventListener('scroll', this.checkPosition);
        const categoryName = this.props.category;
        this.props.repos.categoryService.getCategoryPostsMedia(categoryName)
            .then((cat) => this.setState({ categoryPosts: cat }));
    }

    
    
    checkPosition(event){        
        const windowHeight = window.innerHeight;
        
        if(this.state.categoryPosts && this.state.categoryPosts.data){
            for(let i=0, j=this.state.categoryPosts.data.length; i<j; i++){
                let item = this.state.categoryPosts.data[i];
                if(item!=null && !item.isVisible){
                    const el = document.querySelectorAll('.poseobj-' + item.id);
                    if(el!=null && el.length>0){
                        var positionFromTop = el[0].getBoundingClientRect().top;
                        var porcentage= ((positionFromTop*100) / windowHeight).toFixed(0);
                        //console.log('windows:' + windowHeight + '| positionFromTop:' + positionFromTop + ' %' +porcentage);
                        if(porcentage<=80 && !item.isVisible){
                            this.setState((state,props)=>{
                                    var obj= _.find(state.categoryPosts.data, (p)=>{return p.id===item.id});
                                    obj.isVisible=true;
                                    return {
                                        categoryPosts:{
                                            data:state.categoryPosts.data
                                        }
                                    }
                            });


                            
                        }
                    }
                }
                
            }
        } 
    }

    render(){
        let idx = 0;
        this.listitems = null;
        let withGutters= this.props.noGutters ? '' : 'no-gutters'; 
        const { isOpen } = this.state;

        if(this.state.categoryPosts && this.state.categoryPosts.data){
            this.listitems = this.state.categoryPosts.data.map((s)=>{
                let media=null;
                if(s.media)
                    media ={data:s.media};
                
                idx++;
                return  this.props.renderItem(idx,s.id, s, media);
            });
        }
        
        
        return <div className='wrapper'>
                    <div className={`row ${withGutters}`} >
                        {this.listitems}
                    </div>
                </div>
        
    }
}

export default PostList;