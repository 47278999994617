import React, {Component} from 'react';


class PostListItemMedia extends Component{
    
    render(){
        let item = this.props.item;
        let media = this.props.media;
        let clssName = "col-sm col-md-6 " + this.props.extraClassName;
        let imgs =[];
        let imgs_sm =[];
        let imgSet_md=[];
        
        if(media){
            
            for(let i=0, j= media.data.length; i<j; i++){
                let s = media.data[i];
                const classNameItem= "carousel-item " + (i === 0 ? 'active' : '');
                imgSet_md.push(s.links.full);
                if((i % 3=== 0 && i !==0) || (i== (j - 1))){
                    imgs.push({
                        id:i,
                        imgSet_md : imgSet_md
                    });
                    imgSet_md=[];
                }
                imgs_sm.push({
                    id:i,
                    img : [s.links.full]
                })
            }
        }
        let ssimg=null, ssimg_sm=null;
        if(imgs){
            ssimg = imgs.map((i, index)=>{
                const classNameItem= "carousel-item " + (index === 0 ? 'active' : '');
                const boxes_md = i.imgSet_md.map((i, index)=>{
                    return <div className="col col-md-2 " key={index}><img src={i}  alt="" width="150"/></div>
                });
                return <div className={`${classNameItem} `} key={i.id}>
                            <div className="row ">
                                {boxes_md}
                            </div>
                            
                        </div>
            });
            ssimg_sm = imgs_sm.map((i, index)=>{
                const classNameItem= "carousel-item " + (index === 0 ? 'active' : '');
                return <div className={classNameItem} key={index}>
                            <img className="d-block w-100" src={i.img} key={index} alt="" />
                        </div>
                
            });
               
        }
        
            
            return <div className="col-sm col-md-12">
                        
                        <div className="d-none d-md-block">
                            <div  id="carousel-md" className="carousel slide four-items-carousel " data-ride="carousel">
                                <div className="carousel-inner ">
                                    {ssimg}
                                </div>
                                <a className="carousel-control-prev" href="#carousel-md" role="button" data-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Previous</span>
                                </a>
                                <a className="carousel-control-next" href="#carousel-md" role="button" data-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Next</span>
                                </a>
                            </div>
                        </div>
                        <div className="d-block d-sm-none">
                            <div  id="carousel-sm" className="carousel slide" data-ride="carousel">
                                <div className="carousel-inner">
                                    {ssimg_sm}
                                </div>
                                <a className="carousel-control-prev" href="#carousel-sm" role="button" data-slide="prev">
                                    <span className="carousel-control-prev-icon " aria-hidden="true"></span>
                                    <span className="sr-only">Previous</span>
                                </a>
                                <a className="carousel-control-next" href="#carousel-sm" role="button" data-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Next</span>
                                </a>
                            </div>
                        </div>
                    </div>
        
    }
}

export default PostListItemMedia              