import {EndPoints, SystemConfig} from "../../models/consts";

class NotificationRepository {
    
    sendEmail(mailInfo){
        return  fetch(EndPoints.NotificationMail, {
            method: "POST",
            mode: "cors", // no-cors, cors, *same-origin
            headers: new Headers({
                "Content-Type": "application/json; charset=utf-8",
                'Accept-Language':SystemConfig.getAcceptLanguage()
            }),
            redirect: "follow", // manual, *follow, error
            referrer: "no-referrer", // no-referrer, *client
            body: JSON.stringify(mailInfo)
        })
        .then((response) => response.json())
        .then((responseJson) => {
            return responseJson;
        })
        .catch((error) => {
            console.error(error);
        });
        
    }
}
export default NotificationRepository;