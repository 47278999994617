import React, {Component} from 'react';
import posed from 'react-pose';

class PostBarListItem extends Component{
    
    constructor(){
        super();
        this.state={
            initialPose:'closed'

        }
    }


    render(){
        let item = this.props.item;
        let media = this.props.media;
        let isVisible= item.isVisible  || false;;
        const extraClassName = (this.props.index === 1 )? 'post-item-2-primary' : 'post-item-2';
                
        let clssName = "col-sm col-md-3 ";
        let imgs =null;
        let {initialPose} = this.state;
        if(isVisible && initialPose=='closed'){
            setTimeout(() => {
                this.setState({
                        initialPose:'open'
                    });
            }, 200);
        }
        const Posebox = posed.div({
            closed: {
                y: -50,
                opacity:0
                
              },
            open: {
                y:0,
                opacity:1,
                beforeChildren: true,
                staggerChildren: 50,
                transition: { duration: 400 }
            }
            
        });
        if(media){
            imgs = media.data.map((s)=>{
                return  <img className="img-fluid" key={s.id} src={s.links.full} alt = "" />;
            });
        }
        
        
        const txt = <div className ="wrapper">                          
                        <p className={`title title-${this.props.index}`}>{item.title}</p>
                        <div dangerouslySetInnerHTML={{__html: item.content}} />
                    </div>;

        
        return <div className="col-sm col-md-12 col-lg-3">
                    <Posebox className ={`${extraClassName} poseobj-${item.id}`} initialPose={initialPose} pose={isVisible ? 'open' : 'closed'}>
                        {txt}
                    </Posebox>
                </div>
        
    }
}

export default PostBarListItem;