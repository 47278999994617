import React, { Component } from 'react';
import _ from 'lodash';
class ScrollAnimation extends Component {
  
  constructor(){
    super();
  }
  static index=[]
  static i=1
  componentDidMount() {
    const el = document.querySelectorAll('.poseobj');
    if(el!=null){
      for(let i=0, j=el.length; i<j; i++){
        const obj = el[i];
        let poseId= obj.getAttribute('poseId');
          if(!poseId){
              obj.setAttribute('poseId', ScrollAnimation.i);
              ScrollAnimation.i++;
              console.log(obj);
          }
      }
    }
    window.addEventListener('scroll', this.checkPosition);
    ;
  }
  checkPosition(){
        ScrollAnimation.findPoseElements();
  }
  static findPoseElements(){
    const windowHeight = window.innerHeight;
    const el = document.querySelectorAll('.poseobj');
    if(el!=null){
      for(let i=0, j=el.length; i<j; i++){
        let positionFromTop = el[i].getBoundingClientRect().top;
        const obj = el[i];
        
        if(positionFromTop<=(windowHeight -400)){
            let poseId= obj.getAttribute('poseId');
            if(poseId && !_.find(ScrollAnimation.index,poseId)){
              ScrollAnimation.index.push(poseId);
              
              
            }
        }

      }
    }
  }

  render() {
    
    return (
            
            <section>
            </section>
            
    );
  }
}


export default ScrollAnimation;
