import React, {Component} from 'react';
import {BASE_URI, SystemConfig} from '../models/consts';
import { Link } from "react-router-dom";
import {getLabel} from '../models/labels';
import posed from 'react-pose';
class PostCardListItem extends Component{
    
    constructor(){
        super();
        this.state={
            initialPose:'closed'

        }
    }

    render(){
        let item = this.props.item;
        let media = this.props.media;
        let isVisible= item.isVisible  || false;
        let {initialPose} = this.state;
        if(isVisible && initialPose=='closed'){
            setTimeout(() => {
                this.setState({
                        initialPose:'open'
                    });
            }, 200);
        }
        
        const Posebox = posed.div({
            closed: {
                y: -50,
                opacity:0
                
              },
            open: {
                y:0,
                opacity:1,
                beforeChildren: true,
                staggerChildren: 50,
                transition: { duration: 400 }
            }
            
        });

        let imgs =null;
        if(media){
            imgs = media.data.map((s)=>{
                return  <img className="card-img-top card-img-service" key={s.id} src={s.links.full} alt = "" />;
            });
        }
        
        
        const card = <div className ="card-body card-services">                          
                        
                            <h5 className="card-title">{item.title}</h5>
                        
                        <div className="card-text" dangerouslySetInnerHTML={{__html: item.content}} />
                        <Link className="btn btn-light" to={`${BASE_URI}servicios/${item.name}/${SystemConfig.getLanguage()}`} >
                            {getLabel("view_more")}
                        </Link>
                    </div>;

        
        return <div className="col-sm col-md-4 col-sm-6 pt-2">
                    
                    <Posebox className={`card poseobj-${item.id}`} initialPose={initialPose} pose={isVisible ? 'open' : 'closed'} >
                        {imgs}
                        {card}
                    </Posebox>
                </div>
        
    }
}

export default PostCardListItem;