import {SystemConfig,  EndPoints} from "../../models/consts";


class SectionsRepository {
    getSections(props){
        return  fetch(EndPoints.Pages, {
            method: 'GET',
            headers: new Headers({
                'Accept-Language':SystemConfig.getAcceptLanguage()
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            return responseJson;
        })
        .catch((error) => {
            console.error(error);
        });
        
    }

}
export default SectionsRepository;
