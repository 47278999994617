export function getPages(){
    return {
            "data": [
                {
                    "id": 11,
                    "create_dt": "2018-08-07T02:13:04",
                    "modified_dt": "2018-08-07T03:17:58",
                    "title": "Home",
                    "content": "<p>This is an example of home page bitch!</p>\n",
                    "autor": 1,
                    "parent": null,
                    "type": "Home",
                    "_links": [
                        {
                            "self": {
                                "href": "http://localhost:8888/sidur/api/crm/public/index.php/pages/11"
                            }
                        },
                        {
                            "media": {
                                "href": "http://localhost:8888/sidur/api/crm/public/index.php/pages/11/media"
                            }
                        }
                    ]
                },
                {
                    "id": 14,
                    "create_dt": "2018-08-07T02:29:18",
                    "modified_dt": "2018-08-12T20:53:17",
                    "title": "Main Frame Home",
                    "content": "<p>This is a main frame home</p>\n",
                    "autor": 1,
                    "parent": 11,
                    "type": "",
                    "_links": [
                        {
                            "self": {
                                "href": "http://localhost:8888/sidur/api/crm/public/index.php/pages/14"
                            }
                        },
                        {
                            "media": {
                                "href": "http://localhost:8888/sidur/api/crm/public/index.php/pages/14/media"
                            }
                        }
                    ]
                },
                {
                    "id": 38,
                    "create_dt": "2018-08-26T18:18:43",
                    "modified_dt": "2018-08-26T18:18:43",
                    "title": "Second Frame Home",
                    "content": "<p>testazo</p>\n",
                    "autor": 1,
                    "parent": 14,
                    "type": "",
                    "_links": [
                        {
                            "self": {
                                "href": "http://localhost:8888/sidur/api/crm/public/index.php/pages/38"
                            }
                        },
                        {
                            "media": {
                                "href": "http://localhost:8888/sidur/api/crm/public/index.php/pages/38/media"
                            }
                        }
                    ]
                },
                {
                    "id": 24,
                    "create_dt": "2018-08-09T01:06:37",
                    "modified_dt": "2018-08-26T14:54:25",
                    "title": "Maquinaria",
                    "content": "<p>Maquinaria y equipo con el que contamos en nuestro taller. El cual ponemos a su servicio, para la fabricacion de piezas para su uso en linea de produccion, para su departamento de mantenimiento y maquila de piezas maquinadas urgentes. Plasma XPR 300 control CNC Edge Hypertherm, dimensiones de área de corte efectivos: 10 x 20 ft. Prensa DURMA [&hellip;]</p>\n",
                    "autor": 1,
                    "parent": null,
                    "type": "",
                    "_links": [
                        {
                            "self": {
                                "href": "http://localhost:8888/sidur/api/crm/public/index.php/pages/24"
                            }
                        },
                        {
                            "media": {
                                "href": "http://localhost:8888/sidur/api/crm/public/index.php/pages/24/media"
                            }
                        }
                    ]
                },
                {
                    "id": 20,
                    "create_dt": "2018-08-09T01:05:26",
                    "modified_dt": "2018-08-26T14:54:43",
                    "title": "Nosotros",
                    "content": "<p>Somos una empresa dedicada a la fabricación y reparación de elementos industriales, troqueles, fixtures, ensambles mecánicos, componentes de maquinaria, refacciones, herramientas para su aplicación y fabricación de estructuras metálicas. Contamos con mas de 30 años de experiencia en el mercado de la industria metal mecánica. Misión Satisfacer las necesidades de nuestros clientes en la industria metal [&hellip;]</p>\n",
                    "autor": 1,
                    "parent": null,
                    "type": "",
                    "_links": [
                        {
                            "self": {
                                "href": "http://localhost:8888/sidur/api/crm/public/index.php/pages/20"
                            }
                        },
                        {
                            "media": {
                                "href": "http://localhost:8888/sidur/api/crm/public/index.php/pages/20/media"
                            }
                        }
                    ]
                },
                {
                    "id": 22,
                    "create_dt": "2018-08-09T01:06:14",
                    "modified_dt": "2018-08-26T14:55:01",
                    "title": "Servicios",
                    "content": "<p>Donde sus necesidades se hacen realidad</p>\n",
                    "autor": 1,
                    "parent": null,
                    "type": "Section",
                    "_links": [
                        {
                            "self": {
                                "href": "http://localhost:8888/sidur/api/crm/public/index.php/pages/22"
                            }
                        },
                        {
                            "media": {
                                "href": "http://localhost:8888/sidur/api/crm/public/index.php/pages/22/media"
                            }
                        }
                    ]
                }
            ]
        };
}