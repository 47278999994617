import React, { Component } from 'react';
import StackGrid, { transitions } from "react-stack-grid";
import {BASE_URI, CategoryNames} from '../../models/consts';
import PostList from '../PostList';
import PostCardListItem from '../PostCardListItem'
import Footer from '../Footer';
import { Link } from "react-router-dom";
import Header from '../Header'
import HeaderSection from '../HeaderSection';
import ContactFooter from '../ContactFooter';
import posed from 'react-pose';
import sizeMe from 'react-sizeme'

class Equipments extends Component {
  
  constructor(){
    super();
    this.repos = []; 
    this.state={
      initialPose:'closed',
      isVisible:false,  
      data:{},
      carrousel:{data:[]},
      categoryData:{}
    };
    this.checkPosition = this.checkPosition.bind(this);
  }
  
  componentDidMount() {
    window.addEventListener('scroll', this.checkPosition);
    const sectionName = this.props.sectionName;
    this.props.repos.sectionService.getSectionByName(sectionName)
        .then(data => {
          this.setState({ data: data })
          this.props.repos.mediaService.getMediaPost(data.id)
            .then(data => this.setState({ carrousel: data }));
        });
    this.props.repos.categoryService.getCategoryByName(CategoryNames.Services)
        .then(categories => this.setState({ categoryData: categories }));
  }

  checkPosition(event){        
    const windowHeight = window.innerHeight;
    const el = document.querySelectorAll('.posepage');
    if(el!=null && el.length>0){
        var positionFromTop = el[0].getBoundingClientRect().top;
        var porcentage= ((positionFromTop*100) / windowHeight).toFixed(0);
        if(porcentage<=85 && !this.state.isVisible){
            this.setState({
                      isVisible:true                    
            });
          }
      } 
}

  render() {
    document.documentElement.scrollTop=0;
    let content= this.state.data  ? this.state.data.content :null;
    const { carrousel } = this.state;
    const { scaleDown } = transitions;
    let carrsouselData=null;
    const { 
      size: { 
        width
      } 
    } = this.props;
    if(carrousel){
        carrsouselData = carrousel.data.map((item, index)=>{
        let imgs= null;
        if(item.links){
            imgs= <img src={item.links.full} className="img-fluid "/>
        }
      
        return <div className="stackgriditem"  key={item.id}>
                        {imgs}
                </div>
              
        
    });    
    }
    let {initialPose, isVisible} = this.state;
    if(!isVisible  && width >= 768){
      setTimeout(() => {
          this.setState({
                  isVisible:true
              });
      }, 500);
    }
    if(isVisible && initialPose=='closed'){
        setTimeout(() => {
            this.setState({
                    initialPose:'open'
                });
        }, 500);
    }
        
        const Posebox = posed.div({
          closed: {
            x: '-100%',
            opacity:0
            
          },
          open: {
              x: '0%',
              opacity:1,
              beforeChildren: true,
              staggerChildren: 50,
              transition: { duration: 1000 }
          }
            
        });

    const headerContent = <HeaderSection repos={this.props.repos} category={CategoryNames.Carrousell_Equipment} sectionName={this.props.sectionName} />;
    return (
      <section>
            <Header repos={this.props.repos} headerClassName="section-header" sections={this.props.sections} routeInfo={this.props.routeInfo} content ={headerContent}/>
            <div className="position-relative wrapper-white">
                <div className="container">
                  <div className="row">
                    <div className="col col-md-12">
                        <StackGrid  duration={2000} gutterWidth={10} 
                          columnWidth={width <= 768 ? '50%' : '50%'}
                          monitorImagesLoaded={true} 
                          appear={scaleDown.appear}
                          appeared={scaleDown.appeared}
                          enter={scaleDown.enter}
                          entered={scaleDown.entered}
                          leaved={scaleDown.leaved}>
                            {carrsouselData}
                        </StackGrid>
                    </div>
                  </div>
                  <div className="row">
                    <Posebox className="col col-md-12 posepage" initialPose={initialPose} pose={isVisible ? 'open' : 'closed'}>
                        <section dangerouslySetInnerHTML={{__html: content}} />
                    </Posebox>
                  </div>
                </div>
                
                <ContactFooter/>
                <Footer repos={this.props.repos}/>
            </div>
        </section>
    );
  }
}


export default sizeMe()(Equipments);