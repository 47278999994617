import React, { Component } from 'react';

import {BASE_URI, CategoryNames} from '../../models/consts';
import PostList from '../PostList';
import PostCardListItem from '../PostCardListItem'
import Footer from '../Footer';
import { Link } from "react-router-dom";
import Header from '../Header'
import HeaderSection from '../HeaderSection';
import ContactFooter from '../ContactFooter'
class Services extends Component {
  
  constructor(){
    super();
    this.repos = []; 
    this.state={
        data:{},
        categoryData:{}
    };
  }
  
  componentDidMount() {
    const sectionName = this.props.sectionName;
    this.props.repos.sectionService.getSectionByName(sectionName)
        .then(data => this.setState({ data: data }));
    this.props.repos.categoryService.getCategoryByName(CategoryNames.Services)
        .then(categories => this.setState({ categoryData: categories }));
  }

  render() {
    document.documentElement.scrollTop=0;
    let content= this.state.data  ? this.state.data.content :null;
    let services=null;
    if(this.state.categoryData && this.state.categoryData.children){
      services = this.state.categoryData.children.map((ch)=>{
          
        return <div key={ch.id} className="container padding-bottom-x2">
                <Link className="dark" to={`${BASE_URI}servicios/${ch.name}`} ><h3>{ch.description}</h3></Link>
                <PostList repos={this.props.repos} category={ch.name} noGutters={true} renderItem={(idx, id, item, media)=>{return <PostCardListItem index={idx} key={id} item={item} media={media}/>}}/>
                
              </div>
          
      });
    }
    
    const headerContent = <HeaderSection repos={this.props.repos} category={CategoryNames.Carrousell_Main_Page} sectionName={this.props.sectionName} />;
    return (
      <section>
            <Header repos={this.props.repos} headerClassName="section-header" sections={this.props.sections} routeInfo={this.props.routeInfo} content ={headerContent}/>
            <div className="position-relative wrapper-white">
                <div className="container">
                  <div className="row">
                    <div className="col col-md-12">
                        <section className="padding-md" dangerouslySetInnerHTML={{__html: content}} />
                    </div>
                  </div>
                </div>
                {services}
                <ContactFooter/>
                <Footer repos={this.props.repos}/>
                            
                  
                    
                  
            </div>
        </section>
    );
  }
}


export default Services;
