import {EndPoints, SystemConfig} from "../../models/consts";
import buildUrl from '../../../node_modules/build-url/dist/build-url';

class CateogiresRepository {
    getCategories(){
        return  fetch(EndPoints.Categories, {
            method: 'GET',
            headers: new Headers({
                'Accept-Language':SystemConfig.getAcceptLanguage()
            }),
        })
        .then((response) => response.json())
        .then((responseJson) => {
            return responseJson;
        })
        .catch((error) => {
            console.error(error);
        });
        
    }

    
    getCategoryPosts(categoryId, extraParams){
        let queryParams={};
        if(extraParams){
            queryParams= extraParams;
        }
        if(queryParams)
            queryParams.categories = categoryId;

        let uri= buildUrl(EndPoints.PostsCategory, {
                queryParams: queryParams
        });
        return  fetch(uri, {
            method: 'GET',
            headers: new Headers({
                'Accept-Language':SystemConfig.getAcceptLanguage()
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            return responseJson;
        })
        .catch((error) => {
            console.error(error);
        });
    }

}
export default CateogiresRepository;
