import React, {Component} from 'react';
import NavigateBar from './NavigateBar';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class HeaderHome extends Component{
    
    constructor(){
        super();
        this.state={
            carrousel:{data:[]}
        };
    }

    componentDidMount() {
        const categoryName = this.props.category;
        this.props.repos.categoryService.getCategoryPostsMedia(categoryName)
            .then(data => this.setState({ carrousel: data }));
    }
    
    render(){
        
        const { carrousel } = this.state;

        let carrsouselData=null;
        if(carrousel){
            carrsouselData = carrousel.data.map((item, index)=>{
                let imgs= null;
                if(item.media && item.media.length>0){
                    imgs= <img src={item.media[0].links.full} alt="carrousel"/>
                }
                const classNameItem= "carousel-item " + (index === 0 ? 'active' : '');
                return <div className={classNameItem} key={item.id}>
                                {imgs}
                                <div className="carousel-caption">
                                    <h1>{item.title}</h1>
                                    <section dangerouslySetInnerHTML={{__html: item.content}} />
                                
                                </div>
                            </div>
                       
                
            });    
        }
        
        return <div  id="carouselExampleIndicators" className="carousel slide top-carousel" data-ride="carousel">
                            <div className="carousel-inner">
                                {carrsouselData}
                            </div>
                            <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </a>
                </div>
    }
}

export default HeaderHome;